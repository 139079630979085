import React, { useState, useEffect } from 'react';
import { FolderOutlined, MailOutlined, UserOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Form, Input, Layout, Menu, message, Modal, theme, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { logout, getLocalData } from '../../system/AccountManager';
import { useDispatch } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import { messages as dataMessages, getMessages2, token, socket, getMessages, connect } from '../../system/WS';
const { Header, Sider } = Layout;

function groupbyFrom(messages) {
  console.log(messages)
  const result = {};

  for (const message of messages) {
    const { from } = message;
    if (!result[from]) {
      result[from] = {
        from,
        messages: []
      };
    };
    result[from].messages.push(message);
  };

  return Object.values(result);
};

// const messages = [
//   {
//     id: '35423',
//     from: 'fox@zlr.su',
//     name: 'Зеля',
//     topic: 'фыр',
//     text: 'врур фыр мяу'
//   },
//   {
//     id: '4525637',
//     from: 'foxik@gmail.com',
//     name: 'Лисик',
//     topic: 'мяф',
//     text: 'Я лися'
//   },
//   {
//     id: '347473',
//     from: 'fox3@zlr.su',
//     name: 'Зеля',
//     topic: 'МЯУ',
//     text: 'хы хы хы'
//   },
//   {
//     id: '4356876',
//     from: 'foxfir@zlr.su',
//     name: 'Зеля',
//     topic: 'привет',
//     text: 'приветики фыр фыр'
//   },
//   {
//     id: '23453454788',
//     from: 'fox@gmail.com',
//     name: 'Лисик',
//     topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
//     text: 'а ты лисик?'
//   },
//   {
//     id: '3465835678',
//     from: 'foxfiiir3@zlr.su',
//     name: 'Зеля',
//     topic: 'фыр',
//     text: 'врур фыр мяу'
//   },
//   {
//     id: '36458365786',
//     from: 'foxik4d@gmail.com',
//     name: 'Лисик',
//     topic: 'мяф',
//     text: 'Я лися'
//   },
//   {
//     id: '456383645873456',
//     from: 'fox340@zlr.su',
//     name: 'Зеля',
//     topic: 'МЯУ',
//     text: 'хы хы хы'
//   },
//   {
//     id: '345773468',
//     from: 'foxfir12@zlr.su',
//     name: 'Зеля',
//     topic: 'привет',
//     text: 'приветики фыр фыр'
//   },
//   {
//     id: '345354',
//     from: 'foxyamya@gmail.com',
//     name: 'Лисик',
//     topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
//     text: 'а ты лисик?'
//   },
//   {
//     id: '45684568',
//     from: 'foxii@zlr.su',
//     name: 'Зеля',
//     topic: 'фыр',
//     text: 'врур фыр мяу'
//   },
//   {
//     id: '4568456',
//     from: 'foxikmrr@gmail.com',
//     name: 'Лисик',
//     topic: 'мяф',
//     text: 'Я лися'
//   },
//   {
//     id: '45684568',
//     from: 'fox39@zlr.su',
//     name: 'Зеля',
//     topic: 'МЯУ',
//     text: 'хы хы хы'
//   },
//   {
//     id: '45684568',
//     from: 'foxfirrr@zlr.su',
//     name: 'Зеля',
//     topic: 'привет',
//     text: 'приветики фыр фыр'
//   },
//   {
//     id: '34567',
//     from: 'foxrr@gmail.com',
//     name: 'Лисик',
//     topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
//     text: 'а ты лисик?'
//   },
//   {
//     id: '4579457',
//     from: 'foxfiiir@zlr.su',
//     name: 'Зеля',
//     topic: 'фыр',
//     text: 'врур фыр мяу'
//   },
//   {
//     id: '4579',
//     from: 'foxik3d@gmail.com',
//     name: 'Лисик',
//     topic: 'мяф',
//     text: 'Я лися'
//   },
//   {
//     id: '45684538',
//     from: 'fox346@zlr.su',
//     name: 'Зеля',
//     topic: 'МЯУ',
//     text: 'хы хы хы'
//   },
//   {
//     id: '7457745647',
//     from: 'foxfir31@zlr.su',
//     name: 'Зеля',
//     topic: 'привет',
//     text: 'приветики фыр фыр'
//   },
//   {
//     id: '46457',
//     from: 'foxyaoo@gmail.com',
//     name: 'Лисик',
//     topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
//     text: 'а ты лисик?'
//   },
// ];

console.log(dataMessages)

const toItems = (msgs) => {
  return groupbyFrom(msgs).map(
    (messagesGroup, index) => ({
      key: String(index + 1),
      icon: React.createElement(FolderOutlined),
      style: messagesGroup.messages.find(message => !message.messageRead) 
        ? { fontWeight: 'bold', textDecoration: 'none', backgroundColor: '#2b2b2b' } // Тёмно-серый фон для непрочитанных групп
        : {},
      label: messagesGroup.from,
      children: messagesGroup.messages.map((message, i) => {
        return {
          key: String(index * 4 + i + 1),
          icon: React.createElement(MailOutlined),
          style: !message.messageRead 
            ? { fontWeight: '600', color: '#ffffff', backgroundColor: '#1a1a1a', borderRadius: '4px' } // Лёгкое выделение цветом и жирный текст
            : { color: '#bbbbbb' }, // Серый текст для прочитанных
          label: (
            <Link to={`/m/${message.id}`} style={{ color: !message.messageRead ? '#40a9ff' : '#888888' }}> {/* Голубой для непрочитанных, серый для прочитанных */}
              {message.topic}
            </Link>
          ),
        }
      })
    }),
  );
}

const account = getLocalData();
if(!socket?.connected && account) {
  connect(account.server, account.port, account).then(data => {
    console.log(account);
    console.log(data);
    getMessages();
  });
}

let startedEvent = false;

const MailLayout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmLoading2, setConfirmLoading2] = useState(false);
  const [messages, setMessages] = useState([]);
  const [items, setItems] = useState([]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(() => {
    if (!startedEvent) {
      setMessage();
      startedEvent = true;
    }
  }, []);

  async function setMessage() {
    console.log("started");

    const mes = await getMessages();
    console.log("messages", mes);

    console.log("starte2d");

    setItems(toItems(dataMessages));  // сохраняем результат преобразования в state
    setMessages(dataMessages);

    setTimeout(() => {
      setMessage();
    }, 5000);
  }

  // setTimeout(() => setMessages([
  //   {
  //       id: 1,
  //       from: "system@error.com",
  //       name: "Система",
  //       topic: "Ошибка",
  //       text: "Сообщения ещё не были загружены, и возможно не загрузяться. Если это так, то перезапустите сразицу"
  //   }
  // ]), 100);

  useEffect(() => {
    setItems(toItems(dataMessages));
  }, [dataMessages]);

  const showModal = () => {
    setOpen(true);
  };
  const showModal2 = () => {
    setOpen2(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    form
      .validateFields()
      .then((values) => {
        console.log(values);

        socket.emit("send-message", {
          token,
          mail: {
            to: values["mail-address"],
            subject: values["mail-topic"],
            text: values["mail-content"]
          }
        });
        setOpen(false);
        setConfirmLoading(false);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
        setConfirmLoading(false);
      });

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleOk2 = () => {
    setConfirmLoading2(true);

    form2
      .validateFields()
      .then((values) => {
        values["mail-addresses"].split(/[\s\n]+/).map(addr => addr.trim()).filter(addr => addr.length > 0).forEach(address => {
          socket.emit("send-message", {
            token,
            mail: {
              to: address,
              subject: values["mail-topic"],
              text: values["mail-content"]
            }
          });
        });
        setOpen(false);
        setConfirmLoading(false);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
        setConfirmLoading(false);
      });

    setTimeout(() => {
      setOpen2(false);
      setConfirmLoading2(false);
    }, 2000);
  }

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleCancel2 = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const dispatch = useDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
    <Modal
      title="Написать письмо"
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText='Отмена'
      okText='Отправить'
    >
      <Form
          form={form}
          name="send-mail"
          initialValues={{
            remember: true,
          }}
          style={{
            width: '100%',
          }}
          layout='vertical'
        >
          <Form.Item
            name="mail-address"
            rules={[
              {
                required: true,
                message: 'Вы не указали адресс',
              },
            ]}
          >
            <Input placeholder="Адресс" />
          </Form.Item>

          <Form.Item
            name="mail-topic"
          >
            <Input placeholder="Тема" />
          </Form.Item>

          <Form.Item
            name="mail-content"
          >
            <TextArea placeholder="Сообщение" />
          </Form.Item>
          <Form.Item label="Файлы" valuePropName="fileList">
            <Upload action="/upload.do" listType="picture-card">
              <button style={{ border: 0, background: 'none', color: 'white' }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Загрузить</div>
              </button>
            </Upload>
          </Form.Item>
        </Form>
    </Modal>
    <Modal
      form={form2}
      title="Начать рассылку"
      open={open2}
      onOk={handleOk2}
      confirmLoading={confirmLoading2}
      onCancel={handleCancel2}
      cancelText='Отмена'
      okText='Отправить'
    >
      <Form
          form={form2}
          name="send-mail"
          initialValues={{
            remember: true,
          }}
          style={{
            width: '100%',
          }}
          layout='vertical'
        >
          <Form.Item
            name="mail-addresses"
            rules={[
              {
                required: true,
                message: 'Вы не указали адресс',
              },
            ]}
          >
            <TextArea placeholder="Адреса (по одному на строку)" rows={4} />
          </Form.Item>

          <Form.Item
            name="mail-topic"
          >
            <Input placeholder="Тема" />
          </Form.Item>

          <Form.Item
            name="mail-content"
          >
            <TextArea placeholder="Сообщение" />
          </Form.Item>
          <Form.Item label="Файлы" valuePropName="fileList">
            <Upload action="/upload.do" listType="picture-card">
              <button style={{ border: 0, background: 'none', color: 'white' }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Загрузить</div>
              </button>
            </Upload>
          </Form.Item>
        </Form>
    </Modal>
    <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            height: '48px',
            lineHeight: '48px',
            paddingLeft: '42px',
            paddingRight: '20px',
            alignItems: 'center'
          }}
        >
          <Link to='/' className='Logo' >Mail Client</Link>
          <Dropdown menu={{
            items: [
                {
                  key: '1',
                  label: 'Написать письмо',
                  onClick: showModal
                },
                {
                  key: '2',
                  label: 'Начать рассылку',
                  onClick: showModal2
                },
                {
                  key: '3',
                  danger: true,
                  label: 'Выйти',
                  onClick: () => {
                    logout();
                    dispatch({
                      type: 'SET_DATA',
                      payload: { status: false }
                    });
                  }
                }
              ]
}} placement="bottomRight" trigger={['click']} arrow>
            <Avatar size={36} style={{ marginLeft: '97%', marginTop: '-111px', cursor: 'pointer' }} icon={<UserOutlined />} />
          </Dropdown>
        </Header>
      </Layout>
    <Layout style={{ height: '100%', backgroundColor: '#121212' }}>
      <Sider
        // breakpoint="lg"
        collapsedWidth="80"
        // width="200"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        // style={{ backgroundColor: '#272727', height: '100%' }}
        style={{ backgroundColor: '#272727', height: 'calc(100vh - 48px)', overflowX: 'auto' }}
      >
        <Menu style={{ backgroundColor: '#272727' }} theme="dark" mode="inline" defaultSelectedKeys={['4']} items={items} />
      </Sider>
      <div style={{ margin: '26px', width: '100%' }} >{children}</div>
    </Layout>
    </>
  );
};
export default MailLayout;