import React from 'react'
import Layout from './Layout'
import '../../styles/home.style.css'
import { useParams } from 'react-router-dom'
import { messages, socket, token } from '../../system/WS'; 

function formatMessage(message) {
    return message;
};

// const messages = [
//   {
//     id: '35423',
//     from: 'fox@zlr.su',
//     name: 'Зеля',
//     topic: 'фыр',
//     text: '<p>врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу врур фыр мяу</p>'
//   },
//   {
//     id: '4525637',
//     from: 'foxik@gmail.com',
//     name: 'Лисик',
//     topic: 'мяф',
//     text: 'Я лися'
//   },
//   {
//     id: '347473',
//     from: 'fox3@zlr.su',
//     name: 'Зеля',
//     topic: 'МЯУ',
//     text: 'хы хы хы'
//   },
//   {
//     id: '4356876',
//     from: 'foxfir@zlr.su',
//     name: 'Зеля',
//     topic: 'привет',
//     text: 'приветики фыр фыр'
//   },
//   {
//     id: '23453454788',
//     from: 'fox@gmail.com',
//     name: 'Лисик',
//     topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
//     text: 'а ты лисик?'
//   },
//   {
//     id: '3465835678',
//     from: 'foxfiiir3@zlr.su',
//     name: 'Зеля',
//     topic: 'фыр',
//     text: 'врур фыр мяу'
//   },
//   {
//     id: '36458365786',
//     from: 'foxik4d@gmail.com',
//     name: 'Лисик',
//     topic: 'мяф',
//     text: 'Я лися'
//   },
//   {
//     id: '456383645873456',
//     from: 'fox340@zlr.su',
//     name: 'Зеля',
//     topic: 'МЯУ',
//     text: 'хы хы хы'
//   },
//   {
//     id: '345773468',
//     from: 'foxfir12@zlr.su',
//     name: 'Зеля',
//     topic: 'привет',
//     text: 'приветики фыр фыр'
//   },
//   {
//     id: '345354',
//     from: 'foxyamya@gmail.com',
//     name: 'Лисик',
//     topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
//     text: 'а ты лисик?'
//   },
//   {
//     id: '45684568',
//     from: 'foxii@zlr.su',
//     name: 'Зеля',
//     topic: 'фыр',
//     text: 'врур фыр мяу'
//   },
//   {
//     id: '4568456',
//     from: 'foxikmrr@gmail.com',
//     name: 'Лисик',
//     topic: 'мяф',
//     text: 'Я лися'
//   },
//   {
//     id: '45684568',
//     from: 'fox39@zlr.su',
//     name: 'Зеля',
//     topic: 'МЯУ',
//     text: 'хы хы хы'
//   },
//   {
//     id: '45684568',
//     from: 'foxfirrr@zlr.su',
//     name: 'Зеля',
//     topic: 'привет',
//     text: 'приветики фыр фыр'
//   },
//   {
//     id: '34567',
//     from: 'foxrr@gmail.com',
//     name: 'Лисик',
//     topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
//     text: 'а ты лисик?'
//   },
//   {
//     id: '4579457',
//     from: 'foxfiiir@zlr.su',
//     name: 'Зеля',
//     topic: 'фыр',
//     text: 'врур фыр мяу'
//   },
//   {
//     id: '4579',
//     from: 'foxik3d@gmail.com',
//     name: 'Лисик',
//     topic: 'мяф',
//     text: 'Я лися'
//   },
//   {
//     id: '45684538',
//     from: 'fox346@zlr.su',
//     name: 'Зеля',
//     topic: 'МЯУ',
//     text: 'хы хы хы'
//   },
//   {
//     id: '7457745647',
//     from: 'foxfir31@zlr.su',
//     name: 'Зеля',
//     topic: 'привет',
//     text: 'приветики фыр фыр'
//   },
//   {
//     id: '46457',
//     from: 'foxyaoo@gmail.com',
//     name: 'Лисик',
//     topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
//     text: 'а ты лисик?'
//   },
// ];

export default function Home() {
  const { id } = useParams();

  if (!id) return (
    <Layout><p>Количество сообщений: {messages.length}</p></Layout>
  );

  const message = messages.find(m => m.id == id);

  if (!message) return (
    <Layout><p>Сообщение не найдено</p></Layout>
  );

  socket.emit("message-read", {
    token,
    ID: message.id
  });

  const date = new Date(message.date);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  };

  const formattedDate = date.toLocaleDateString('ru-RU', options);

  return (
    <div className='home-container'>
        <Layout>
            <div className="message-container">
              <div className="info">
                <p className="line">От: {message.from}</p>
                <p className="line">Имя: {message.name}</p>
                <p className="line">Тема: {message.topic}</p>
                <p className="line">Дата: {formattedDate}</p>
              </div>
              <div className='content'>
                <div dangerouslySetInnerHTML={{__html: formatMessage(message.text)}} />
              </div>
            </div>
        </Layout>
    </div>
  )
}